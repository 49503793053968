<template>
	<div class="m-login">
		<div class="m-login-form">
			<div class="m-login-box">
				<div class="login-text">
                    <p :class="{active:tab_num==1}" @click="tabClick(1)">手机注册 <span></span></p>
                    <p :class="{active:tab_num==2}" @click="tabClick(2)">邮箱注册 <span></span></p>
                </div>
				<el-form ref="form" :rules="rules" :model="form" label-width="0" v-show="tab_num==1">
					<el-form-item prop="mobile">
						<el-input v-model="form.mobile" placeholder="请输入手机号">
						</el-input>
					</el-form-item>
                    <el-form-item prop="code">
						<el-row :gutter="10">
							<el-col :span="16">
								<el-input v-model="form.code" placeholder="短信验证码"></el-input>
							</el-col>
							<el-col :span="8">
								<el-button type="primary" class="m-btn" v-if="codeTime == '获取验证码' || codeTime == '再次获取验证码'" @click="sendCode">{{codeTime}}</el-button>
								<el-button type="primary" class="m-btn" v-else >{{codeTime}}s</el-button>
							</el-col>
						</el-row>
					</el-form-item>
					<el-form-item prop="password"> 
						<el-input type="password" v-model="form.password" placeholder="请输入密码" show-password>
						</el-input>
					</el-form-item>
                    <el-form-item prop="password1"> 
						<el-input type="password" v-model="form.password1" placeholder="请确认密码" show-password>
						</el-input>
					</el-form-item>
                    <div class="mianze">
                        <el-checkbox v-model="checked"></el-checkbox>
                        <p @click="goTo('statement',{id:2})">同意免责条款 </p>
                    </div>
                    <el-form-item>
						<el-button style="background: #437EFE;border: 0;width: 100%;height: 45px;font-size:18px;" type="primary" @click="onSubmit">注册</el-button>
					</el-form-item>                    
				</el-form>

                <el-form ref="form1" :rules="rules1" :model="form1" label-width="0" v-show="tab_num==2">
					<el-form-item prop="email">
						<el-input v-model="form1.email" placeholder="请输入邮箱">
						</el-input>
					</el-form-item>
                    <el-form-item prop="code">
						<el-row :gutter="10">
							<el-col :span="16">
								<el-input v-model="form1.code" placeholder="邮箱激活码"></el-input>
							</el-col>
							<el-col :span="8">
								<el-button type="primary" class="m-btn" v-if="codeTime1 == '获取激活码' || codeTime1 == '再次获取激活码'" @click="sendCode1">{{codeTime1}}</el-button>
								<el-button type="primary" class="m-btn" v-else >{{codeTime1}}s</el-button>
							</el-col>
						</el-row>
					</el-form-item>
					<el-form-item prop="password"> 
						<el-input type="password" v-model="form1.password" placeholder="请输入密码" show-password>
						</el-input>
					</el-form-item>
                    <el-form-item prop="password1"> 
						<el-input type="password" v-model="form1.password1" placeholder="请确认密码" show-password>
						</el-input>
					</el-form-item>
                    <div class="mianze">
                        <el-checkbox v-model="checked"></el-checkbox>
                        <p  @click="goTo('statement',{id:2})">同意免责条款 </p>
                    </div>
                    <el-form-item>
						<el-button style="background: #437EFE;border: 0;width: 100%;height: 45px;font-size:18px;" type="primary" @click="onSubmit1">注册</el-button>
					</el-form-item>                    
				</el-form>


                <div class="m-login-bottom">
                    <div class="m-login-huiyuan" @click="goTo('/member')">
                        <img src="../assets/images/hysj.png" alt="">
                        <p>会员升级</p>
                    </div>
                    <div class="m-login-tz">
                        <span @click="goTo('/login')">登录</span>
                        <span>|</span>
                        <span @click="goTo('/forget')">忘记密码</span>
                    </div>
                </div>
			</div>
            <!-- <div class="web-name">
                <img src="../assets/images/web-left.png" alt="">
                <span>河南海外仓供应链管理服务有限公司</span>
                <img src="../assets/images/web-right.png" alt="">
            </div> -->
		</div>
	</div>
</template>

<script>
	import {emailRegister,email_send,mobileRegister,mobile_send} from "../api/index"
	export default {
		data() {
			var checkPhone = (rule, value, callback) => {
				if (!(/^1[3456789]\d{9}$/.test(value))) {
					callback(new Error('手机号不正确'));
				} else {
					callback()
				}
			}
			return {
                tab_num:1,
                checked:false,
				rules: {
					mobile: [{
							required: true,
							message: '请输入手机号',
							trigger: ['blur', 'change']
						},
						{
							validator: checkPhone,
							trigger: ['blur', 'change']
						}
					],
                    code: [{
						required: true,
						message: '请输入验证码',
						trigger: ['blur', 'change']
					}],
					password: [{
						required: true,
						message: '请输入密码',
						trigger: ['blur', 'change']
					}],
                    password1: [{
						required: true,
						message: '请输入密码',
						trigger: ['blur', 'change']
					}],
				},
                rules1: {                    
					email: [
                        { required: true, message: '请输入邮箱', trigger: ['blur', 'change'] },
                        { type: 'email', message: '请输入正确的邮箱', trigger: ['blur', 'change'] }
                    ],
                    code: [{
						required: true,
						message: '请输入激活码',
						trigger: ['blur', 'change']
					}],
					password: [{
						required: true,
						message: '请输入密码',
						trigger: ['blur', 'change']
					}],
                    password1: [{
						required: true,
						message: '请再次输入密码',
						trigger: ['blur', 'change']
					}],
				},
				form: {
					mobile:'',
                    code: '',
					password:'',
                    password1:''
				},
                form1: {
					email:'',
                    code: '',
					password:'',
                    password1:''
				},
                codeTime:'获取验证码',
                codeTime1:'获取激活码'
			}
		},
		methods: {
            tabClick(num){
                this.tab_num = num;
            },
            goTo(url,query) {
				if (url=='statement') {
					this.$router.push({ 
						path: url ,
						query
					});
				}else{
					this.$router.replace({ 
						path: url ,
						query
					});
				}
                
            },
            //发送验证码
			async sendCode(){
				if(!(/^1[3456789]\d{9}$/.test(this.form.mobile))){
					this.$message.error('手机号错误')
					return false;
				}
				let res = await mobile_send({mobile:this.form.mobile})
				// console.log(res);
				if(res.code == 200){
					this.$message.success(res.message)
					this.codeTime = 60
					let _time = setInterval(()=>{
						if(this.codeTime <= 0){
							this.codeTime = '再次获取验证码'
							clearInterval(_time)
							return false
						}
						this.codeTime -= 1
					},1000)
				}else{
					this.$message.error(res.message)
				}
			},
            //发送邮箱激活码
			async sendCode1(){
				if(!(/^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/.test(this.form1.email))){
					this.$message.error('邮箱错误')
					return false;
				}
				let res = await email_send({email:this.form1.email})
				// console.log(res);
				if(res.code == 200){
					this.$message.success(res.message)
					this.codeTime1 = 60
					let _time = setInterval(()=>{
						if(this.codeTime1 <= 0){
							this.codeTime1 = '再次获取激活码'
							clearInterval(_time)
							return false
						}
						this.codeTime1 -= 1
					},1000)
				}else{
					this.$message.error(res.message)
				}
			},
			// 手机号注册
			onSubmit() {
				this.$refs.form.validate(async (valid)=>{
					if(valid){
						if (!this.checked) {
							this.$message.error('请勾选并同意免责条款')
							return false
						}
						if (this.form.password!==this.form.password1) {
							this.$message.error('两次密码输入不一致')
							return false;
						}
						mobileRegister({
							...this.form
						}).then((res)=>{
							// console.log(res)
							if (res.code==200) {
								this.$message.success(res.message)
								setTimeout(() => {
									this.$router.replace({path:'/login',});
								}, 500);  
							}else{
								this.$message.error(res.message)
							}
						})
					}
				})
			},
			// 邮箱注册
            onSubmit1() {
				this.$refs.form1.validate(async (valid)=>{
					if(valid){
						if (!this.checked) {
							this.$message.error('请勾选并同意免责条款')
							return false
						}
						if (this.form1.password!==this.form1.password1) {
							this.$message.error('两次密码输入不一致')
							return false;
						}
						emailRegister({
							...this.form1
						}).then((res)=>{
							// console.log(res)
							if (res.code==200) {
								this.$message.success(res.message)
								setTimeout(() => {
									this.$router.replace({path:'/login',});
								}, 500);  
							}else{
								this.$message.error(res.message)
							}
						})
					}
				})
			}
		}
	}
</script>

<style lang="less" scoped>
/deep/ .el-input__inner{
    border: 1px solid #A0A0A0;
    height: 45px;
    // line-height: 50px;
    font-size: 15px;
}
/deep/ .el-checkbox__inner{transform: scale( 1.3);}
/deep/ .el-checkbox__label{ font-size: 16px;}
/deep/ .el-checkbox__inner{border-color: #A0A0A0;}
	.m-login {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: url(../assets/images/login-bg.png) no-repeat;
		background-size: cover;
		background-position: center;
		display: flex;
		justify-content: flex-end;
		align-items: center;

		.m-login-form {
			// display: flex;
			position: relative;
            margin-right: 18%;            
			.m-login-box {
				display: flex;
				flex-direction: column;
				width: 350px;
				margin: 0px auto;
                background:#fff;
                border-radius: 10px;
                padding:40px 58px;
                .m-btn {
					padding: 0;
                    width: 100%;
                    height: 45px;
                    background: #fff;
                    font-size: 14px;
                    color: #676767;
                    border: 1px solid #A0A0A0;
				}
                .mianze{
                    margin-bottom: 22px;
                    display: flex;
                    p{
                        margin-left: 15px;cursor: pointer;
                        &:hover{
                            color: #5670F2;
                            text-decoration: underline;
                        }
                    }
                }
                .login-text{
                    display: flex;
                    font-size: 22px;
                    border-bottom: 1px solid #A0A0A0;
                    margin-bottom: 35px;
                    p{  
                        width: 50%;
                        cursor: pointer;                        
                        padding-bottom: 30px;
                        position: relative;
                        text-align: center;
                        span{
                            width: 0;
                            height: 3px;
                            background: #6982FE;
                            position: absolute;
                            bottom: -1px;
                            left: 50%;
                            transform: translateX(-50%);
                            transition: all .2s;
                        }
                    }
                    .active{
                        color: #6982FE;
                        transition: all .2s;
                        span{
                            width: 100%;
                            transition: all .2s;
                        }
                    }
                }
                .m-login-bottom{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .m-login-huiyuan{
                        display: flex;
                        align-items: center;
                        color: #999999;
                        font-size: 16px;       
                        cursor: pointer;                 
                        img{width: 20px;margin-right: 5px;}
                    }
                    .m-login-tz{
                        color: #999999;font-size: 16px;cursor: pointer;
                        span:nth-child(2){padding: 0 10px;}
                    }
                }
			}

            .web-name{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 25px;
                color: #fff;
                span{margin: 0 5px;}        
                img{width: 90px;}        
            }
		}
	}
</style>
